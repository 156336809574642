export const DOCUMENT_LIMIT = 5;
const MAX_LENGTH = 360;

export const UPDATE_DAY = 1695306600000;
export const TOOL_TYPES = { generator: 'generator', builder: 'builder', custom: 'custom' };
export const GENERATOR_TOOLS = [
  {
    key: 'idea-generator',
    name: 'Idea Generator',
    descriptionTitle: 'Shotgun Style Assessment Ideas',
    description: [
      'Quickly generate quirky, out of the box ideas to assess any unit or individual lesson.\n',
      'The goal of this tool is to help you save time when it comes to brainstorming ideas related to engaging students.\n',
      'This tool is used best in combination with the others to help you quickly breeze through your lesson and unit planning.',
    ],
    message: '💡 Generating Ideas!',
    inputs: [
      {
        name: 'subject',
        label: 'Topic to generate ideas for',
        placeholder: 'e.g. "The Civil War"',
        maxLength: MAX_LENGTH,
      },
      {
        name: 'gradeLevel',
        label: 'Grade Level (And subject, if applicable)',
        placeholder: 'e.g. "5th Grade Social Studies"',
      },
    ],
    button: 'Generate Ideas',
    api: 'shotgunCompletion',
    historyLink: 'idea-generator',
  },
  {
    key: 'real-life-benefits',
    historyLink: 'real-life-benefits',
    name: 'Real World Benefits',
    descriptionTitle:
      'Generate a list that contains a few of the real world benefits to learning about any subject or topic.',
    description: [
      'Be as brief or detailed with the topics as youd like. The more detailed, the better. \n',
      'Great for writing on the whiteboard in the morning. \n',
      'The goal of this tool is to help finally answer the age old question, "Why are we learning this?"',
    ],
    message: '🥳 Benefits coming right up!',
    inputs: [
      {
        name: 'subject',
        label: 'Subject or lesson plan to generate benefits for',
        placeholder: 'e.g. "The Civil War"',
        maxLength: MAX_LENGTH,
      },
    ],
    button: 'Generate Benefits',
    api: 'benefitsCompletion',
  },
  {
    key: 'research-project-generator',
    historyLink: 'research-project-generator',
    name: 'Research Project Generator',
    descriptionTitle:
      'Save time and quickly spin up research projects for any grade level, subject, unit, lesson, or general topic.',
    description: [
      'Generates projects in stunning detail. \n',
      // eslint-disable-next-line
      "If you provide a detailed prompt, the project generated will stick closely to what you've provided. If you're feeling lucky, be brief! \n",
    ],
    message: '🔎 Generating a project!',
    inputs: [
      {
        name: 'subject',
        label: 'Subject, unit, or lesson to generate project for',
        placeholder: 'e.g. "The Civil War"',
        maxLength: MAX_LENGTH,
      },
      {
        name: 'gradeLevel',
        label: 'Grade Level',
        placeholder: 'e.g. "5th Grade"',
      },
    ],
    button: 'Generate Project Idea',
    api: 'researchProjectCompletion',
  },
  {
    key: 'writing-prompts',
    historyLink: 'writing-prompts',
    name: 'Writing Prompts',
    descriptionTitle: 'Generate long form writing prompts with ease.',
    description: [
      'Currently, its best to phrase your topic/prompt in the form of a question. Although, may still get great results using single words or statements. \n',
      'The goal of this tool is to create a long form writing prompt that promotes critical and creative thinking. \n',
    ],
    message: '📝 Generating a writing prompt!',
    inputs: [
      {
        name: 'subject',
        label: 'Topic/Prompt',
        placeholder: 'e.g. "The Civil War"',
        maxLength: MAX_LENGTH,
      },
    ],
    button: 'Generate Writing Prompt',
    api: 'writingPromptCompletion',
  },
  {
    key: 'student-reports',
    historyLink: 'student-reports',
    name: 'Student Reports',
    descriptionTitle:
      'Save time and quickly create student reports to send to parents. The more details you provide, the better the report will be.',
    description: [],
    message: '📝 Generating a student report!',
    inputs: [
      {
        name: 'studentName',
        label: 'Students Name',
        placeholder: 'e.g. "John Doe"',
        maxLength: MAX_LENGTH,
      },
      {
        name: 'potential',
        label: 'Areas where student excels/shows potential',
        placeholder: 'e.g. "John is a great student who is always on time and ready to learn."',
        maxLength: MAX_LENGTH,
      },
      {
        name: 'improvement',
        label: 'Areas where student needs improvement',
        placeholder: 'e.g. "John needs to work on his handwriting and spelling."',
        maxLength: MAX_LENGTH,
      },
      {
        name: 'gradeLevel',
        label: 'Your grade level and subject',
        placeholder: 'e.g. "5th Grade Social Studies"',
      },
    ],
    button: 'Generate Report',
    api: 'studentReportsCompletion',
  },
  {
    key: 'parent-emails',
    historyLink: 'parent-emails',
    name: 'Parent Emails',
    // eslint-disable-next-line
    descriptionTitle: "Generate an email to send out to a student's parent.",
    description: [
      'The more detailed the better. \n',
      'Can easily detect between positive and negative feedback. Feel free to include both, or just one. \n',
      'Please note, the AI Freestyle tool also does a great job at generating emails of all types. \n',
    ],
    message: '📧 Generating an email!',
    inputs: [
      {
        name: 'firstFeedback',
        label: 'First feedback to address',
        placeholder: 'e.g. "John is a great student who is always on time and ready to learn."',
        maxLength: MAX_LENGTH,
      },
      {
        name: 'secondFeedback',
        label: 'Second feedback to address',
        placeholder: 'e.g. "John needs to work on his handwriting and spelling."',
        maxLength: MAX_LENGTH,
      },
      {
        name: 'thirdFeedback',
        label: 'Third feedback to address',
        placeholder: 'e.g. "John needs to work on his handwriting and spelling."',
        maxLength: MAX_LENGTH,
      },
      {
        name: 'fourthFeedback',
        label: 'Fourth feedback to address',
        placeholder: 'e.g. "John needs to work on his handwriting and spelling."',
        maxLength: MAX_LENGTH,
      },
    ],
    button: 'Generate Email',
    api: 'parentEmailsCompletion',
  },
  {
    key: 'lessonplannerv2',
    name: 'Lesson Plan',
    descriptionTitle: 'Save time and quickly draft lesson plans for any subject matter.',
    description: [],
    message: '📝 Generating a lesson plan!',
    inputs: [
      {
        name: 'subject',
        label: 'Topic or lesson',
        placeholder: 'e.g. "The Civil War"',
        maxLength: MAX_LENGTH,
      },
      {
        name: 'gradeLevel',
        label: 'Course',
        placeholder: 'e.g. "5th Grade Social Studies"',
      },
    ],
    button: 'Generate Lesson Plan',
    api: 'lessonPlannerV2Completion',
    historyLink: 'lesson-plans',
  },
  {
    key: 'educational-handout',
    name: 'Educational Handout',
    descriptionTitle:
      'Create a detailed and structured handout to prepare yourself or students for a lesson. This tool goes great paired with the Lesson Planner!',
    description: [],
    message: '📝 Generating an educational handout!',
    inputs: [
      {
        name: 'subject',
        label: 'Handout should cover',
        placeholder: 'Introducing to computer science',
        maxLength: MAX_LENGTH,
      },
      {
        name: 'gradeLevel',
        label: 'Course',
        placeholder: 'Online programming course',
      },
    ],
    button: 'Generate Educational Handout',
    api: 'educationalHandoutCompletion',
    historyLink: 'educational-handouts',
  },
  {
    key: 'context-builder',
    name: 'Context Builder',
    descriptionTitle:
      // eslint-disable-next-line
      "Scaffold your lesson with a context builder. This tool will ensure your students don't miss a beat when building their understanding about a topic.",
    description: [],
    message: '📝 Generating a context builder!',
    inputs: [
      {
        name: 'subject',
        label: 'Topic ',
        placeholder: 'e.g. "The Great Depression"',
        maxLength: MAX_LENGTH,
      },
      {
        name: 'gradeLevel',
        label: 'Course',
        placeholder: '7th Grade US History',
      },
    ],
    button: 'Generate Context Builder',
    api: 'vocabCompletion',
    historyLink: 'context-builders',
  },
  {
    key: 'freestyle',
    name: 'AI Freestyle',
    descriptionTitle: 'Ask for advice, order a document to be created, test the limits of Copilot!',
    description: [],
    inputs: [
      {
        name: 'subject',
        label: 'Type your text ',
        placeholder: 'Exploring Ukrainian History',
        multiline: true,
        rows: 10,
      },
    ],
    api: 'freeStyleCompletion',
    historyLink: 'freestyle',
  },
];

export const BUILDER_TOOLS = [
  {
    key: 'powerpoints',
    name: 'PowerPoint Generator',
    descriptionTitle:
      'Generate a 15 slide, educational PowerPoint on any topic. Simply provide a few details and let Copilot do the rest!',
    description: [],
    message: '📝 Generating a PowerPoint!',
    inputs: [
      {
        name: 'subject',
        label: 'Topic ',
        placeholder: 'The Cold War',
      },
      {
        name: 'gradeLevel',
        label: 'PowerPoint should cover',
        placeholder: 'How it started, what it was, and how it ended',
      },
    ],
    button: 'Generate PowerPoint',
    api: 'powerPointCompletion',
  },
  {
    key: 'recipe-builder',
    name: 'Recipe Builder',
    descriptionTitle:
      'Combine all of the best tools into one creation. Generate a lesson plan, PowerPoint, and more - all at once.',
    description: [],
    message: '📝 Generating a recipe!',
    inputs: [
      {
        name: 'subject',
        label: 'Topic or Lesson Area',
        placeholder: 'The Cold War',
      },
      {
        name: 'gradeLevel',
        label: 'Class & Grade level',
        placeholder: '7th Grade US History',
      },
    ],
    button: 'Generate Resources',
    api: 'recipeCompletion',
  },
];

export const CONFIG_REPLACEMENT_TAGS = {
  lessonplannerv2: {
    title: 'Lesson Plan:',
    regex: [
      {
        title: 'Aim',
        regex: { text: /<aim>/g },
      },
      {
        title: 'Objectives',
        regex: { text: /<objectives>/g },
      },
      {
        title: 'Possible Materials Needed',
        regex: { text: /<materials>/g },
      },
      {
        title: 'Anticipatory Set',
        regex: { text: /<anticipatory>/g },
      },
      {
        title: 'Modeled Practice',
        regex: { text: /<modeled>/g },
      },
      {
        title: 'Guided Practice',
        regex: { text: /<guided>/g },
      },
      {
        title: 'Independent Practice',
        regex: { text: /<independent>/g },
      },
      {
        title: 'Common Areas of Struggle',
        regex: { text: /<struggles>/g },
      },
      {
        title: 'Closure',
        regex: { text: /<closure>/g },
      },
    ],
  },
  'educational-handout': {
    regex: [
      {
        regex: {
          title: /<title>|<\/title>/g,
        },
      },
      {
        title: 'Handout Covers',
        regex: { text: /<handoutcovers>|<\/handoutcovers>/g },
      },
      {
        regex: {
          title: /<header([1-9]|10)>|<\/header([1-9]|10)>/g,
          text: /<body([1-9]|10)>|<\/body([1-9]|10)>/g,
        },
      },
    ],
  },
  'context-builder': {
    title: 'Context for:',
    regex: [
      {
        regex: {
          text: /<(definition|example|word)([1-9]|10)>/g,
        },
      },
    ],
  },
};
