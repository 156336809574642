import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import TabIcon from '@mui/icons-material/Tab';

const links = [
  { id: 1, text: 'Dashboard', path: '/', icon: <TabIcon /> },

  {
    id: 2,
    text: 'unit planner',
    path: 'unit-planner',
    icon: <CollectionsBookmarkIcon />,
  },
  { id: 3, text: 'Workshop', path: 'workshop', icon: <AutoFixHighIcon /> },
  { id: 4, text: 'History', path: 'creations', icon: <AccessTimeIcon /> },
];

export default links;
